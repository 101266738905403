export default {
  data: () => ({
    meta: {
      page: 1,
      per_page: 7,
      total_page: 1,
      total_data: 1
    },
    search: {
      name: ''
    },
    loading: false
  }),
  methods: {
    async prev() {
      if (this.meta.page > 1) {
        this.loadData(this.meta.page - 1);
      }
    },
    async next() {
      if (this.meta.page < this.meta.total_pages) {
        this.loadData(this.meta.page + 1);
      }
    },
    switchLoading() {
      this.loading = !this.loading;
    },
    loadingTrue() {
      this.loading = true;
    },
    loadingFalse() {
      this.loading = false;
    }
  },
  watch: {
    'search.name': function (search) {
      return this.loadData({
        page: 1, per_page: this.meta.per_page, search, debounce: true
      });
    },
    'meta.page': function (page) {
      return this.loadData({
        page, per_page: this.meta.per_page, search: this.search.name, debounce: false
      });
    },
    loading(isLoading) {
      if (isLoading) {
        this.$vs.loading({ container: '#div-with-loading', scale: 0.6 });
      } else {
        this.$vs.loading.close('#div-with-loading > .con-vs-loading');
      }
    }
  },
  async mounted() {
    await this.loadData({});
  }
};
