import debounce from 'debounce-promise';
import http from '../plugins/http';

const list = (page, per_page, name) => http.get('/customer', {
  params: {
    page, per_page, name, phone: name
  }
});

const update = (id, data) => http.put(`/customer/${id}`, data);

export default {
  detail: id => http.get(`/customer/${id}`),
  list,
  listDebounce: debounce(list, 400),
  update
};
