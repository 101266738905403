<template>
  <div class="vx-row">
    <div class="vx-col w-3/4 mx-auto self-center">
      <vx-card>
        <div>
          <vs-input icon-after="true" icon-pack="feather" icon="icon-search" label-placeholder="Search" v-model="search.name" class="w-full no-icon-border is-label-placeholder" />
        </div>
        <br>
        <div class="vs-con-loading__container" id="div-with-loading">
          <vs-table stripe :data="customers" @selected="navigate">
            <template slot="thead">
              <vs-th>Name</vs-th>
              <vs-th>Phone</vs-th>
              <vs-th>Member Since</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="tr.id" v-for="(tr) in data" :data="tr">
                <vs-td :data="tr.name">
                  {{ tr.name }}
                </vs-td>

                <vs-td :data="tr.phone">
                  {{ tr.phone }}
                </vs-td>

                <vs-td :data="tr.date">
                  {{ tr.date }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
        <div slot="footer">
          <div class="flex">
            <vs-pagination :total="meta.total_page" v-model="meta.page" :max="7" />
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import CustomerRepo from '@/repositories/customer_repo';
import Pagination from '@/utils/mixins/pagination';

export default {
  mixins: [Pagination],
  data: () => ({
    customers: []
  }),
  methods: {
    loadData({
      page, per_page = 8, search, debounce
    }) {
      this.loadingTrue();
      const func = debounce ? CustomerRepo.listDebounce : CustomerRepo.list;
      return func(page, per_page, search)
        .then(({ data: { data: customers, pagination: meta } }) => {
          this.customers = customers.map(item => ({
            id: item.id, name: item.name, phone: item.phone, date: moment(item.created_at).format('DD / MM / YYYY')
          }));
          this.meta = meta;
          this.loadingFalse();
        });
    },
    navigate(item) {
      this.$router.push(`/customers/${item.id}`);
    }
  }
};
</script>
